import { TECH_IMG_PATH, SERVICE_IMG_PATH } from '@/data/constant.static';
export const TECHNOLOGIES = [
  {
    name: "Ionic",
    description: "",
    link: "https://ionicframework.com/",
    img: `${TECH_IMG_PATH}ionic.svg`,
  },
  {
    name: "Angular",
    description: "",
    link: "https://angular.io/",
    img: `${TECH_IMG_PATH}angular-icon.svg`,
  },
  {
    name: "Vue",
    description: "",
    link: "https://vuejs.org/",
    img: `${TECH_IMG_PATH}vue-9.svg`,
  },
  {
    name: "NativeScript",
    description: "",
    link: "https://nativescript.org/",
    img: `${TECH_IMG_PATH}nativescript.svg`,
  },
  {
    name: "Vuetify",
    description: "",
    link: "https://vuetifyjs.com/en/",
    img: `${TECH_IMG_PATH}vuetify.svg`,
  },
  {
    name: "NodeJs",
    description: "",
    link: "https://nodejs.org/en/",
    img: `${TECH_IMG_PATH}nodejs.svg`,
  },
  {
    name: "TypeScript",
    description: "",
    link: "https://www.typescriptlang.org/",
    img: `${TECH_IMG_PATH}typescript.svg`,
  },
  {
    name: "JavaScript",
    description: "",
    link: "https://www.javascript.com/",
    img: `${TECH_IMG_PATH}javascript.svg`,
  },
  {
    name: "HTML5",
    description: "",
    link: "https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/HTML5",
    img: `${TECH_IMG_PATH}html5.svg`,
  },
  {
    name: "CSS3",
    description: "",
    link: "https://developer.mozilla.org/en-US/docs/Web/CSS",
    img: `${TECH_IMG_PATH}css3.svg`,
  },
  {
    name: "Android",
    description: "",
    link: "https://www.android.com/intl/en_in/",
    img: `${TECH_IMG_PATH}android.svg`,
  },
  {
    name: "iOS",
    description: "",
    link: "https://developer.apple.com/ios/",
    img: `${TECH_IMG_PATH}apple.svg`,
  },
  {
    name: "Google Cloud",
    description: "",
    link: "https://cloud.google.com/",
    img: `${TECH_IMG_PATH}google-cloud-1.svg`,
  },
  {
    name: "AWS",
    link:
      "https://aws.amazon.com/free/?trk=ps_a134p000003yhlXAAQ&trkCampaign=acq_paid_search_brand&sc_channel=ps&sc_campaign=acquisition_IN&sc_publisher=google&sc_category=core-main&sc_country=IN&sc_geo=APAC&sc_outcome=Acquisition&sc_detail=aws&sc_content=Brand_Core_aws_e&sc_matchtype=e&sc_segment=453325184782&sc_medium=ACQ-P|PS-GO|Brand|Desktop|SU|Core-Main|Core|IN|EN|Text&s_kwcid=AL!4422!3!453325184782!e!!g!!aws&ef_id=Cj0KCQjw6-SDBhCMARIsAGbI7Ui3epgL65JyyffMFq2P3Z5J8NPSibCNZRVoGvBvzcH8yRpvUsq_XK4aAu38EALw_wcB:G:s&s_kwcid=AL!4422!3!453325184782!e!!g!!aws&all-free-tier.sort-by=item.additionalFields.SortRank&all-free-tier.sort-order=asc&awsf.Free%20Tier%20Types=*all&awsf.Free%20Tier%20Categories=*all",
    img: `${TECH_IMG_PATH}aws.svg`,
  },
  // {
  //   name: "elastic",
  //   img: `${TECH_IMG_PATH}elastic.svg`,
  // },
  {
    name: "Flutter",
    link:
      "https://flutter.dev/?gclid=Cj0KCQjw6-SDBhCMARIsAGbI7UjHkgY1TdZsm--gwQ5MgT2CevfOKQe479o48woX8hnGCNBRp696WOMaAg36EALw_wcB&gclsrc=aw.ds",
    img: `${TECH_IMG_PATH}flutter.svg`,
  },
  {
    name: "jQuery",
    description: "",
    link: "https://jquery.com/",
    img: `${TECH_IMG_PATH}jquery.svg`,
  },
  {
    name: "MongoDB",
    link:
      "https://www.mongodb.com/cloud/atlas/efficiency?utm_source=google&utm_campaign=gs_apac_india_search_core_brand_atlas_desktop&utm_term=mongodb&utm_medium=cpc_paid_search&utm_ad=e&utm_ad_campaign_id=12212624347&gclid=Cj0KCQjw6-SDBhCMARIsAGbI7UimuN4gjEj-NpAQ2mepDeis079m-IcSg1AhI1xiJnFRL1phe5iljxEaAiWvEALw_wcB",
    img: `${TECH_IMG_PATH}mongodb.svg`,
  },
  {
    name: "MySql",
    description: "",
    link: "https://www.mysql.com/",
    img: `${TECH_IMG_PATH}mysql.svg`,
  },
];
export const SERVICES = [
  {
    name: "Software Development",
    coverImg: `${SERVICE_IMG_PATH}covers/software-development.jpeg`, link: "services", img: `${SERVICE_IMG_PATH}software-dev.png`,
    description:
      "Create complex enterprise software, ensure reliable software integration, modernise your legacy system.",
  },
  {
    name: "Mobile App Development",
    coverImg: `${SERVICE_IMG_PATH}covers/mobile-app-cover.jpeg`, link: "services", img: `${SERVICE_IMG_PATH}mobile-app-dev.png`,
    description:
      "Create an impactful mobile app that fits your brand and industry within a shorter time frame.",
  },
  {
    name: "Dedicated Development Team",
    coverImg: `${SERVICE_IMG_PATH}covers/dev-team.jpeg`, link: "services", img: `${SERVICE_IMG_PATH}development team.png`,
    description:
      "Hire a loyal software development team with niche skills and deep expertise.",
  },
  {
    name: "IT Consulting",
    coverImg: `${SERVICE_IMG_PATH}covers/consulting.jpeg`, link: "services", img: `${SERVICE_IMG_PATH}it consulting.png`,
    description:
      "Trust our top minds to eliminate workflow pain points,implement new tech, and consolidate app portfolios.",
  },
  {
    name: "UX / UI Design",
    coverImg: `${SERVICE_IMG_PATH}covers/ui-ux.jpeg`, link: "services", img: `${SERVICE_IMG_PATH}ui ux design.png`,
    description:
      "Build the product you need on time with an experienced team that uses a clear and effective design process.",
  },
  {
    name: "QA Testing",
    coverImg: `${SERVICE_IMG_PATH}covers/qa.jpeg`, link: "services", img: `${SERVICE_IMG_PATH}testing.png`,
    description:
      "Turn to our experts to perform comprehensive,multi-stage testing and auditing of your software.",
  },
];
export const INDUSTRIES = [
  {
    name: "Construction",
    coverImg: `${SERVICE_IMG_PATH}covers/construction.jpeg`, link: "industries", img: `${SERVICE_IMG_PATH}construction.png`,
    description:
      "Automatic hazard detection, remote cooperation, and more with our AR solutions.",
  },
  {
    name: "Insurance",
    coverImg: `${SERVICE_IMG_PATH}covers/insurance.jpeg`, link: "industries", img: `${SERVICE_IMG_PATH}insurance.png`,
    description:
      "Claim management, alerts, AI, cloud and data solutions, SOS video, and more.",
  },
  {
    name: "Travel & Hospitality",
    coverImg: `${SERVICE_IMG_PATH}covers/travel.jpeg`, link: "industries", img: `${SERVICE_IMG_PATH}travels .png`,
    description:
      "Internet of Things solutions, latest tech, all the essential hotel app features.",
  },
  {
    name: "Sports & Teams",
    coverImg: `${SERVICE_IMG_PATH}covers/sport.jpeg`, link: "industries", img: `${SERVICE_IMG_PATH}sport.png`,
    description:
      "Sports apps, chatbots, Internet of Things, beacons, geofencing, and many more.",
  },
  {
    name: "Healthcare",
    coverImg: `${SERVICE_IMG_PATH}covers/health.jpeg`, link: "industries", img: `${SERVICE_IMG_PATH}healthcare.png`,
    description:
      "Notifications and scheduling, patient tracking, data solutions, and many more.",
  },
  {
    name: "Retail & eCommerce",
    coverImg: `${SERVICE_IMG_PATH}covers/retails and ecommearce.jpeg`, link: "industries", img: `${SERVICE_IMG_PATH}retail.png`,
    description:
      "Gamification, AR, navigation, Big Data, ecommerce software development.",
  },
  {
    name: "Events & Venues",
    coverImg: `${SERVICE_IMG_PATH}covers/event and vanue.jpeg`, link: "industries", img: `${SERVICE_IMG_PATH}event.png`,
    description:
      "Beacons, streaming, AR, data solutions, event pass, venue management software.",
  },
  {
    name: "Musicians & Bands",
    coverImg: `${SERVICE_IMG_PATH}covers/musicians and bands.jpeg`, link: "industries", img: `${SERVICE_IMG_PATH}musicians.png`,
    description:
      "Map and tour dates, in-app chat and shop, streaming, subscription, and more.",
  },
];